import {createTheme} from '@mui/material/styles';

export const appTheme = createTheme({
  palette: {
    primary: {
      main: '#AD1231',
      dark: '#8a132b',
    },
    secondary: {
      main: '#FFFFFF',
      dark: '#dadfe6',
      light: '#f0f1f5',
    },
    info: {
      main: '#000000',
    },
    background: {
      default: '#F2F5FA',
    },
    action: {
      disabledBackground: '#E4E6EC',
      disabled: '#E4E6EC',
    },
  },
  typography: {
    button: {
      textTransform: 'none',
    },
  },
});
