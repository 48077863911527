import {Link} from 'react-router-dom';

import {ImageType} from '../../api/api-types';

import './MaskThumbnail.css';

type MaskThumbnailPropType = {
  image: ImageType;
};

export const MaskThumbnail = ({image}: MaskThumbnailPropType) => {
  return (
    <div className="image-thumbnail">
      <div className="image-thumbnail-name"></div>
      <div className="image-thumbnail-container">
        <img className="image-thumbnail-image" src={image.image} />
      </div>
    </div>
  );
};

export default MaskThumbnail;
