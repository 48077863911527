import {useEffect, useContext} from 'react';
import {Routes, Route} from 'react-router-dom';
import {useQuery} from '@tanstack/react-query';

import {ActionType} from './context/ActionTypes';
import {getCSRF, getUserSession} from './api/api-functions';

import {ImagesOverview} from './routes/ImagesOverview/ImagesOverview';
import {ChartPage} from './routes/ChartPage/ChartPage';
import {LoginPage} from './routes/LoginPage/LoginPage';
import {LogoutPage} from './routes/LogoutPage/LogoutPage';

import {AppContext} from './context/AppContext';

export const StateManager = () => {
  const {appState, appDispatch} = useContext(AppContext);
  const csrfQueryInfo = useQuery(['csrf'], () => getCSRF());
  const sessionQueryInfo = useQuery(['session'], () => getUserSession());

  useEffect(() => {
    if (csrfQueryInfo.isSuccess) {
      appDispatch({
        actionType: ActionType.setCSRFToken,
        value: csrfQueryInfo.data['X-CSRFToken'],
      });
      localStorage.setItem('X-CSRFToken', csrfQueryInfo.data['X-CSRFToken']);
    }
  }, [csrfQueryInfo.data]);

  useEffect(() => {
    if (sessionQueryInfo.isSuccess) {
      if (sessionQueryInfo.data.username) {
        appDispatch({
          actionType: ActionType.setUserName,
          value: sessionQueryInfo.data.username,
        });
      }
      if (sessionQueryInfo.data.username) {
        appDispatch({
          actionType: ActionType.setUserId,
          value: sessionQueryInfo.data.user_id,
        });
      }
      if (sessionQueryInfo.data['X-CSRFToken']) {
        appDispatch({
          actionType: ActionType.setCSRFToken,
          value: sessionQueryInfo.data['X-CSRFToken'],
        });
        localStorage.setItem(
          'X-CSRFToken',
          sessionQueryInfo.data['X-CSRFToken']
        );
      }
      if (sessionQueryInfo.data.isAuthenticated !== undefined) {
        appDispatch({
          actionType: ActionType.setAuthenticated,
          value: sessionQueryInfo.data.isAuthenticated,
        });
      }
    }
  }, [sessionQueryInfo.data]);

  return appState.isAuthenticated ? (
    <Routes>
      <Route path="/" element={<ImagesOverview />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/logout" element={<LogoutPage />} />
      <Route path="/edit_chart/:image_id" element={<ChartPage />} />
    </Routes>
  ) : appState.isAuthenticated === null ? (
    <div>Loading, please wait...</div>
  ) : (
    <Routes>
      <Route path="/" element={<LoginPage />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/edit_chart/:image_id" element={<LoginPage />} />
    </Routes>
  );
};
