import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import {LabelEditor, Anchor, Direction} from './LabelEditor';

import './TickEditor.css';

type TickEditorPropType = {
  label: string;
  position: number; // Position of tick on scale
  minPosition: number;
  maxPosition: number;
  tickStart: number; // Start of thick line (corrdinate orthogonal to tick position)
  tickEnd: number; // End of thick line (corrdinate orthogonal to tick position)
  axisDirection: string;
  setPosition?: (position: number) => void;
  onUpdate?: (position: number, label: string) => void;
  onRemove?: () => void;
};

export const TickEditor = ({
  label,
  position,
  minPosition,
  maxPosition,
  tickStart,
  tickEnd,
  axisDirection,
  setPosition,
  onUpdate,
  onRemove,
}: TickEditorPropType) => {
  const cursorStyle = {
    vertical: 'row-resize',
    horizontal: 'col-resize',
  }[axisDirection];

  const onMouseDown = (event: React.MouseEvent<HTMLElement>) => {
    const startPosition = {x: event.pageX, y: event.pageY};
    const changeCursorElement = document.getElementsByTagName('body')[0];
    changeCursorElement.style.cursor = cursorStyle ? cursorStyle : '';

    function onMouseMove(event: MouseEvent) {
      const newPosition =
        axisDirection === 'horizontal'
          ? position + event.pageX - startPosition.x
          : position + event.pageY - startPosition.y;
      if (event.pageX !== startPosition.x || event.pageY !== startPosition.y) {
        setPosition && setPosition(newPosition);
      }
      event.preventDefault();
      event.stopPropagation();
      return false;
    }

    function onMouseUp(event: MouseEvent) {
      document.body.removeEventListener('mousemove', onMouseMove);
      const newPosition =
        axisDirection === 'horizontal'
          ? position + event.pageX - startPosition.x
          : position + event.pageY - startPosition.y;
      setPosition && setPosition(newPosition);
      changeCursorElement.style.cursor = '';
      onUpdate && onUpdate(newPosition, label);
    }

    document.body.addEventListener('mousemove', onMouseMove);
    document.body.addEventListener('mouseup', onMouseUp, {once: true});
  };

  return axisDirection === 'horizontal' ? (
    <>
      <div
        onMouseDown={onMouseDown}
        className="tick-line tick-line-horizontal"
        style={{
          top: tickStart - 10,
          left: position,
          height: tickEnd - tickStart + 10,
          width: 3,
        }}
      ></div>
      <LabelEditor
        label={label}
        onChange={label => {
          onUpdate && onUpdate(position, label);
        }}
        x={position}
        y={tickStart}
        anchor={Anchor.North}
        direction={Direction.Horizontal}
      />
      <Tooltip title="Remove Tick">
        <IconButton
          className="remove-tick-button"
          style={{
            left: position - 17,
            top: tickEnd - 8,
          }}
          color="primary"
          onClick={onRemove}
        >
          <RemoveCircleOutlineIcon fontSize="small" />
        </IconButton>
      </Tooltip>
    </>
  ) : (
    <>
      <div
        onMouseDown={onMouseDown}
        className="tick-line tick-line-vertical"
        style={{
          top: position,
          left: tickStart,
          width: tickEnd - tickStart + 10,
          height: 3,
        }}
      ></div>
      <LabelEditor
        label={label}
        onChange={label => {
          onUpdate && onUpdate(position, label);
        }}
        x={tickEnd - 12}
        y={position}
        anchor={Anchor.East}
        direction={Direction.Horizontal}
      />
      <Tooltip title="Remove Tick">
        <IconButton
          className="remove-tick-button"
          style={{
            left: tickStart - 28,
            top: position - 17,
          }}
          color="primary"
          onClick={onRemove}
        >
          <RemoveCircleOutlineIcon fontSize="small" />
        </IconButton>
      </Tooltip>
    </>
  );
};
