import * as React from 'react';
import {useMutation, useQueryClient} from '@tanstack/react-query';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import CircularProgress from '@mui/material/CircularProgress';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import {exportDataTables} from '../../api/api-functions';
import {DataTableType} from '../../api/api-types';

type DataExportMenuPropType = {
  tables: Array<DataTableType>;
};

type ExportType = {
  tableIds: Array<number>;
  format: string;
};

enum FileFormats {
  csv = 'csv',
  xlsx = 'xlsx',
  png = 'png',
}

export const DataExportMenu = ({tables}: DataExportMenuPropType) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const [loadingCsv, setLoadingCsv] = React.useState(false);
  const [loadingXlsx, setLoadingXlsx] = React.useState(false);
  const [loadingPng, setLoadingPng] = React.useState(false);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const exportTablesMutation = useMutation(
    (param: ExportType) => exportDataTables(param.tableIds, param.format),
    {
      onSuccess: data => {
        const parts = data.url.split('.');
        const format = parts[parts.length - 1];
        initFileDownload(
          data.url,
          FileFormats[format as keyof typeof FileFormats]
        );
        handleClose();
        setLoadingCsv(false);
        setLoadingXlsx(false);
        setLoadingPng(false);
      },
    }
  );

  const exportFile = (format: string) => {
    const tableIds = tables.map(t => t.id);
    switch (format) {
      case 'csv':
        setLoadingCsv(true);
        break;
      case 'xlsx':
        setLoadingXlsx(true);
        break;
      case 'png':
        setLoadingPng(true);
        break;
    }

    exportTablesMutation.mutate({tableIds: tableIds, format: format});
  };

  const initFileDownload = (fileUrl: string, format: FileFormats) => {
    const formatMime = {
      csv: 'text/csv',
      xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      png: 'image/png',
    };
    fetch(fileUrl, {
      method: 'GET',
      headers: {
        'Content-Type': formatMime[format],
      },
    })
      .then(response => response.blob())
      .then(blob => {
        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `table.${format}`);

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        if (link.parentNode) {
          link.parentNode.removeChild(link);
        }
      });
  };

  return (
    <>
      <Button
        id="data-export-menu-button"
        aria-controls={open ? 'data-export-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
      >
        Export
      </Button>

      <Menu
        elevation={0}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        id="data-export-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={() => exportFile('csv')} disableRipple>
          CSV
          {loadingCsv && (
            <CircularProgress
              size={18}
              color="primary"
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                marginTop: '-12px',
                marginLeft: '-12px',
              }}
            />
          )}
        </MenuItem>
        <MenuItem onClick={() => exportFile('xlsx')} disableRipple>
          XLSX
          {loadingXlsx && (
            <CircularProgress
              size={18}
              color="primary"
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                marginTop: '-12px',
                marginLeft: '-12px',
              }}
            />
          )}
        </MenuItem>
        <MenuItem onClick={() => exportFile('png')} disableRipple>
          PNG
          {loadingPng && (
            <CircularProgress
              size={18}
              color="primary"
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                marginTop: '-12px',
                marginLeft: '-12px',
              }}
            />
          )}
        </MenuItem>
      </Menu>
    </>
  );
};

export default DataExportMenu;
