import {useState, useEffect, useRef} from 'react';
import {Stage, Layer, Image} from 'react-konva';

import {AxisType, DataTableType} from '../../api/api-types';

import {AxisControl} from './AxisControl';
import {TablePlot} from './TablePlot';

type ChartCanvasPropType = {
  image: HTMLImageElement;
  axes: Array<AxisType>;
  dataTables?: Array<DataTableType>;
};

export const ChartCanvas = ({image, axes, dataTables}: ChartCanvasPropType) => {
  const canvasContainerRef = useRef<HTMLDivElement>(null);
  const [canvasSize, setCanvasSize] = useState({width: 100, height: 100});
  const [canvasContainerHeight, setCanvasContainerHeight] = useState(100);

  const [imageScale, setImageScale] = useState(1);

  const axesDict = Object.fromEntries(axes.map(axis => [axis.id, axis]));

  // Handling canvas resize events and chart image scaling
  const handleCanvasScale = () => {
    if (canvasContainerRef && canvasContainerRef.current) {
      let cWidth = canvasContainerRef.current.clientWidth;
      let cHeight = canvasContainerRef.current.clientHeight;
      const maxHeight = window.innerHeight * 0.7;
      let scaleX = canvasContainerRef.current.clientWidth / image.width;
      scaleX = Math.min(scaleX, 1);
      cHeight = image.height * scaleX;
      if (cHeight > maxHeight) {
        scaleX = maxHeight / image.height;
        scaleX = Math.min(scaleX, 1);
        cHeight = scaleX * image.height;
        cWidth = scaleX * image.width;
      }

      setImageScale(scaleX);
      // Set height of canvas container to scaled height of the image
      setCanvasContainerHeight(cHeight);

      setCanvasSize({
        width: cWidth,
        height: cHeight,
      });
    }
  };

  useEffect(() => {
    handleCanvasScale();
    window.addEventListener('resize', handleCanvasScale);
  }, []);

  return (
    <div
      className="chart-editor"
      ref={canvasContainerRef}
      style={{height: canvasContainerHeight}}
    >
      <Stage
        className="chart-editor-canvas"
        width={canvasSize.width}
        height={canvasSize.height}
      >
        <Layer>
          <Image
            image={image}
            x={0}
            y={0}
            offsetX={0}
            offsetY={0}
            scale={{x: imageScale, y: imageScale}}
          />
          {axes.map((axis: AxisType) => (
            <AxisControl
              key={`axis_${axis.id}`}
              axis={axis}
              scale={imageScale}
            />
          ))}
          {dataTables &&
            dataTables.map(dataTable => (
              <TablePlot
                key={`data-line-${dataTable.id}`}
                scale={imageScale}
                dataTable={dataTable}
                xAxis={axesDict[dataTable.x_axis]}
                yAxis={axesDict[dataTable.y_axis]}
              />
            ))}
        </Layer>
      </Stage>
    </div>
  );
};
