import {useState, useEffect, useRef} from 'react';
import {useQuery, useQueryClient, useMutation} from '@tanstack/react-query';

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';

import {ModalDialog} from '../ModalDialog/ModalDialog';
import {ColorPicker} from '../ColorPicker/ColorPicker';
import {DataTableType} from '../../api/api-types';
import {
  updateDataTable,
  getAxis,
  updateAxis,
  deleteDataTable,
} from '../../api/api-functions';

type DataTableSettingsDialogPropType = {
  tables: Array<DataTableType>;
};

export const DataTableSettingsDialog = ({
  tables,
}: DataTableSettingsDialogPropType) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const queryClient = useQueryClient();

  const xAxisQueryInfo = useQuery(['axis', tables[0].x_axis], () =>
    getAxis(tables[0].x_axis)
  );

  const updateTableMutation = useMutation(updateDataTable, {
    onSuccess: data => {
      queryClient.invalidateQueries(['data_table', data.image]);
    },
  });

  const updateAxisMutation = useMutation(updateAxis, {
    onSuccess: data => {
      queryClient.invalidateQueries(['data_table', data.image]);
      queryClient.invalidateQueries(['chart_objects', data.image]);
    },
  });

  const deleteTableMutation = useMutation(deleteDataTable, {
    onSuccess: () => {
      queryClient.invalidateQueries(['data_table', tables[0].image]);
    },
  });

  const onDelete = async (data_table_id: number) => {
    deleteTableMutation.mutate(data_table_id);
  };

  const updateTable = (table: DataTableType) => {
    updateTableMutation.mutate(table);
  };

  const updateXAxis = (label: string) => {
    if (xAxisQueryInfo.isSuccess) {
      const xAxis = {...xAxisQueryInfo.data};
      xAxis.label = label;
      updateAxisMutation.mutate(xAxis);
    }
  };

  const handleClickOpen = () => {
    setDialogOpen(true);
  };

  const handleClose = () => {
    setDialogOpen(false);
  };

  return (
    <>
      <Button variant="contained" color="primary" onClick={handleClickOpen}>
        Settings
      </Button>
      <ModalDialog
        show={dialogOpen}
        title="Table settings"
        onClose={() => {
          setDialogOpen(false);
        }}
        fullScreen={false}
      >
        <Typography component="div" gutterBottom>
          X-Axis:
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={10}>
            <TextField
              label="Label"
              variant="outlined"
              fullWidth
              defaultValue={tables[0].columns[0]}
              onChange={event => updateXAxis(event.target.value)}
            />
          </Grid>
        </Grid>
        <Typography component="div" mt={3} gutterBottom>
          Data Series:
        </Typography>

        {tables.map(table => (
          <Grid
            container
            spacing={3}
            mb={2}
            justifyContent="center"
            alignItems="center"
            key={`edit_table_${table.id}`}
          >
            <Grid item xs={6}>
              <TextField
                label="Label"
                variant="outlined"
                fullWidth
                defaultValue={table.name}
                onChange={event => {
                  const newTable = {...table};
                  newTable.name = event.target.value;
                  updateTable(newTable);
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                inputProps={{inputMode: 'numeric', pattern: '[0-9]*'}}
                label="Precision"
                variant="outlined"
                defaultValue={table.precision}
                onChange={event => {
                  const newTable = {...table};
                  newTable.precision = Number(event.target.value);
                  updateTable(newTable);
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <ColorPicker
                color={table.color}
                onColorChange={color => {
                  const newTable = {...table};
                  newTable.color = color;
                  updateTable(newTable);
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <IconButton onClick={() => onDelete(table.id)}>
                <DeleteIcon />
              </IconButton>
            </Grid>
          </Grid>
        ))}

        <Typography component="div"></Typography>
      </ModalDialog>
    </>
  );
};

export default DataTableSettingsDialog;
