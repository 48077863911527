import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';

import './ModalDialog.css';

type DialogAction = {
  label: string;
  action: () => void;
};

type ModalDialogPropType = {
  show: boolean;
  title?: string;
  actions?: DialogAction[];
  onClose?: () => void;
  fullScreen?: boolean;
  children?: React.ReactNode;
};

export const ModalDialog = (props: ModalDialogPropType) => {
  const {show, title, actions, onClose, fullScreen, children, ...other} = props;

  return show ? (
    <Dialog
      open={show}
      onClose={onClose}
      fullScreen={fullScreen}
      PaperProps={fullScreen ? {sx: {width: '80vw', height: '80vh'}} : {}}
      {...other}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText component={'span'} sx={{color: '#000'}}>
          {children}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {actions &&
          actions.map((item: DialogAction) => (
            <Button
              key={item.label}
              onClick={item.action}
              color="primary"
              variant="contained"
            >
              {item.label}
            </Button>
          ))}

        <Button onClick={onClose} color="primary" variant="outlined">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  ) : (
    <> </>
  );
};

export default ModalDialog;
