import {useReducer} from 'react';
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';

import {BrowserRouter} from 'react-router-dom';

import {ThemeProvider} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import {appTheme} from './themes/AppTheme';

import {StateManager} from './StateManager';

import {appReducer, initialState, AppContext} from './context/AppContext';

const queryClient = new QueryClient();

import './App.css';

function App() {
  const [appState, appDispatch] = useReducer(appReducer, initialState);

  return (
    <QueryClientProvider client={queryClient}>
      <AppContext.Provider value={{appState, appDispatch}}>
        <BrowserRouter>
          <ThemeProvider theme={appTheme}>
            <CssBaseline>
              <StateManager />
            </CssBaseline>
          </ThemeProvider>
        </BrowserRouter>
      </AppContext.Provider>
    </QueryClientProvider>
  );
}

export default App;
