import './Line.css';

type Point = {
  x: number;
  y: number;
};

type LinePropType = {
  p1: Point;
  p2: Point;
  color?: string;
};

function getLinelength(p1: Point, p2: Point) {
  return Math.sqrt((p1.x - p2.x) ** 2 + (p1.y - p2.y) ** 2);
}

function getLineAngle(p1: Point, p2: Point) {
  return (Math.atan2(p2.y - p1.y, p2.x - p1.x) * 180) / Math.PI;
}

export const Line = ({p1, p2, color}: LinePropType) => {
  const length = getLinelength(p1, p2);
  const angle = getLineAngle(p1, p2);
  const minX = Math.min(p1.x, p2.x);
  const minY = Math.min(p1.y, p2.y);
  const shiftY = (Math.sin((angle / 180) * Math.PI) * length) / 2;
  const shiftX = ((1 - Math.cos((angle / 180) * Math.PI)) * length) / 2;

  return (
    <div
      className="line-segment"
      style={{
        borderColor: color,
        left: minX - Math.abs(shiftX),
        top: minY + Math.abs(shiftY),
        width: length,
        transform: `rotate(${angle}deg)`,
      }}
    ></div>
  );
};

export default Line;
