import React, {useState, KeyboardEvent} from 'react';

import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

export const useNotification = () => {
  const [show, setShow] = useState(false);
  const [notification, setNotification] = useState('');
  const [icon, setIcon] = useState('info');

  const showNotification = (notificationText: string, iconType: string) => {
    setIcon(iconType);
    setNotification(notificationText);
    setShow(true);
  };

  const closeHandler = () => {
    setNotification('');
    setShow(false);
    setIcon('');
  };

  const inputPressKeyHandler = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' || e.key === 'Escape') closeHandler();
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={closeHandler}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const notificationElement = (
    <div>
      <Snackbar
        open={show}
        autoHideDuration={6000}
        onClose={closeHandler}
        message={notification}
        action={action}
      />
    </div>
  );

  return {showNotification, notificationElement};
};

export default useNotification;
