import axios, {AxiosRequestConfig} from 'axios';

// axios.defaults.xsrfCookieName = 'csrftoken';
// axios.defaults.xsrfHeaderName = 'X-CSRFTOKEN';
axios.defaults.withCredentials = true;

export const apiInstance = axios.create({
  baseURL: '/charts/v1/',
  // baseURL: 'http://localhost:8000/charts/v1/',
});

apiInstance.interceptors.request.use((config: AxiosRequestConfig) => {
  const token = localStorage.getItem('X-CSRFToken');
  if (token) {
    if (config.headers === undefined) {
      config.headers = {};
    }
    config.headers['X-CSRFToken'] = token;
  }
  return config;
});

export const authInstance = axios.create({
  baseURL: '/auth_api/v1',
  // baseURL: 'http://localhost:8000/auth_api/v1',
});

authInstance.interceptors.request.use((config: AxiosRequestConfig) => {
  const token = localStorage.getItem('X-CSRFToken');
  if (token) {
    if (config.headers === undefined) {
      config.headers = {};
    }
    config.headers['X-CSRFToken'] = token;
  }
  return config;
});

export default apiInstance;
