import {Rect, Text} from 'react-konva';

import {AxisType} from '../../api/api-types';

type AxisControlPropType = {
  axis: AxisType;
  scale: number;
};

export const AxisControl = ({axis, scale}: AxisControlPropType) => {
  return (
    <>
      <Rect
        key={axis.id}
        x={axis.x * scale}
        y={axis.y * scale}
        width={axis.width * scale}
        height={axis.height * scale}
        stroke={'#333'}
      />
      <Text
        x={axis.x * scale}
        y={axis.y * scale}
        key={'axis_${axis.id}'}
        text={axis.label}
      />
    </>
  );
};

export default AxisControl;
