import * as React from 'react';
import {Navigate} from 'react-router-dom';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

import {AppContext} from '../../context/AppContext';
import {ActionType} from '../../context/ActionTypes';

import login_picture from './login_picture.png';
import logo from './chartable_logo.png';

import {loginUser} from '../../api/api-functions';

import './LoginPage.css';

export function LoginPage() {
  const {appState, appDispatch} = React.useContext(AppContext);
  const [loginError, setLoginError] = React.useState('');
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const username = data.get('username');
    const password = data.get('password');
    if (username && password) {
      loginUser(username.toString(), password.toString())
        .then(result => {
          appDispatch({
            actionType: ActionType.setUserName,
            value: result.username,
          });
          appDispatch({
            actionType: ActionType.setUserId,
            value: result.user_id,
          });
          if (result['X-CSRFToken']) {
            appDispatch({
              actionType: ActionType.setCSRFToken,
              value: result['X-CSRFToken'],
            });
          }
          if (result.isAuthenticated) {
            appDispatch({
              actionType: ActionType.setAuthenticated,
              value: result.isAuthenticated,
            });
          }
          setLoginError('');
        })
        .catch(error => {
          setLoginError(error.response.data.detail);
        });
    } else {
      setLoginError('Please provide username and password');
    }
  };

  // For logged users redirect to home page
  if (appState && appState.isAuthenticated) {
    return <Navigate to="/" />;
  }

  return (
    <Grid
      container
      component="main"
      direction="row"
      justifyContent="center"
      alignItems="center"
      sx={{height: '100vh'}}
    >
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Grid
          container
          alignItems="stretch"
          justifyContent="center"
          sx={{height: '40em', maxHeight: '100vh'}}
        >
          <Box
            component={Grid}
            item
            md={4}
            lg={4}
            display={{xs: 'none', sm: 'none', md: 'block', lg: 'block'}}
            sx={{
              backgroundImage: `url(${login_picture})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}
          >
            <Grid
              container
              alignItems="flex-end"
              sx={{height: '100%', px: 2, py: 2}}
            >
              <Grid item xs={12} sm={12} md={12}>
                <Typography component="h1" variant="h5" sx={{color: '#FFF'}}>
                  Welcome to Chartable
                </Typography>
                <Typography sx={{color: '#FFF'}} variant="body1">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <Grid
            item
            xs={12}
            sm={8}
            md={4}
            lg={3}
            component={Paper}
            elevation={6}
            square
          >
            <Box
              sx={{
                mx: 4,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                alignContent: 'space-between',
                justifyContent: 'space-evenly',
                height: '100%',
              }}
            >
              <img className="login-logo" src={logo} />
              <Typography component="h1" variant="h5">
                Sign in
              </Typography>
              <Box
                component="form"
                noValidate
                onSubmit={handleSubmit}
                sx={{mt: 1}}
              >
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="username"
                  label="Username"
                  name="username"
                  autoFocus
                  error={loginError ? true : undefined}
                  helperText={loginError}
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  error={loginError ? true : undefined}
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{mt: 3, mb: 2}}
                >
                  Login
                </Button>
                <Grid
                  container
                  sx={{flexDirection: 'column', alignItems: 'end'}}
                >
                  <Grid item>
                    <Link href="#" variant="body2">
                      Forgot password?
                    </Link>
                  </Grid>
                  <Divider flexItem sx={{mt: 3, mb: 3}} />
                  <Grid item>
                    <Typography variant="body2">
                      Don't have an account?&nbsp;
                      <Link href="#" variant="body2">
                        {'Register Now'}
                      </Link>
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default LoginPage;
