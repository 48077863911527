import {useEffect, useState, useRef} from 'react';

import './LabelEditor.css';

export enum Anchor {
  North,
  East,
  South,
  West,
  None,
}

export enum Direction {
  Horizontal,
  Vertical,
}

type LabelEditorPropType = {
  x: number;
  y: number;
  label?: string;
  onChange?: (label: string) => void;
  anchor?: Anchor;
  direction?: Direction;
};

export const LabelEditor = ({
  x,
  y,
  label = '',
  onChange,
  anchor = Anchor.None,
  direction,
}: LabelEditorPropType) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const hiddenSpanRef = useRef<HTMLDivElement>(null);
  const [inputContent, setInputContent] = useState('');

  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  useEffect(() => {
    setInputContent(label);
    if (containerRef && containerRef.current) {
      setHeight(containerRef.current.clientHeight);
      setWidth(containerRef.current.clientWidth);
    }
  }, []);

  useEffect(() => {
    if (hiddenSpanRef && hiddenSpanRef.current) {
      setWidth(hiddenSpanRef.current.offsetWidth + 10);
    }
  }, [inputContent]);

  let left = x;
  let top = y;

  if (direction === Direction.Vertical) {
    switch (anchor) {
      case Anchor.North:
        left = x - width / 2;
        top = y - width / 2 - height / 2;
        break;
      case Anchor.South:
        left = x - width / 2;
        top = y + width / 2 - height / 2;
        break;
      case Anchor.West:
        left = x - width / 2 + height / 2;
        top = y - height / 2;
        break;
      case Anchor.East:
        left = x - width / 2 - height / 2;
        top = y - height / 2;
        break;
      case Anchor.None:
        left = x - width / 2;
        top = y - height / 2;
        break;
    }
  } else {
    switch (anchor) {
      case Anchor.North:
        left = x - width / 2;
        top = y;
        break;
      case Anchor.South:
        left = x - width / 2;
        top = y - height;
        break;
      case Anchor.West:
        left = x;
        top = y - height / 2;
        break;
      case Anchor.East:
        left = x - width;
        top = y - height / 2;
        break;
      case Anchor.None:
        left = x - width / 2;
        top = y - height / 2;
        break;
    }
  }

  return (
    <div
      ref={containerRef}
      className="label-editor"
      style={{
        left: left,
        top: top,
        transform: direction === Direction.Vertical ? 'rotate(-90deg)' : '',
      }}
    >
      <input
        className="label-edit"
        defaultValue={label}
        onChange={event => {
          setInputContent(event.target.value);
          onChange && onChange(event.target.value);
        }}
        style={{width: width}}
      />
      <span ref={hiddenSpanRef} className="hidden-label-editor-content">
        {inputContent}
      </span>
    </div>
  );
};

export default LabelEditor;
