import {useEffect, useState} from 'react';

import DataGrid, {textEditor, RowsChangeData} from 'react-data-grid';

import {DataTableType} from '../../api/api-types';

import 'react-data-grid/lib/styles.css';
import './DataTableEditor.css';

function getColumns(tables: Array<DataTableType>) {
  const columns = [
    {
      key: tables[0].columns[0],
      name: tables[0].columns[0],
      editor: textEditor,
      width: 50,
      maxWidth: 300,
      resizable: true,
    },
  ];
  for (let i = 0; i < tables.length; i++) {
    columns.push({
      key: tables[i].name,
      name: tables[i].name,
      editor: textEditor,
      width: 50,
      maxWidth: 300,
      resizable: true,
    });
  }
  return columns;
}

interface Row {
  [key: string]: string;
}

function getRows(tables: Array<DataTableType>) {
  const rowsData = [];
  for (let i = 0; i < tables[0].table.length; i++) {
    const r: Row = {};
    r[tables[0].columns[0]] = tables[0].table[i][0];
    for (let j = 0; j < tables.length; j++) {
      const value = tables[j].table[i][1];
      r[tables[j].name] = parseFloat(value).toFixed(tables[j].precision);
    }
    rowsData.push(r);
  }
  return rowsData;
}

type DataTableEditorPropType = {
  tables: Array<DataTableType>;
  onTableChange?: (t: DataTableType) => void;
};

export const DataTableEditor = ({
  tables,
  onTableChange,
}: DataTableEditorPropType) => {
  const [tableColumns, setColumns] = useState(getColumns(tables));
  const [tableRows, setTableRows] = useState(getRows(tables));

  useEffect(() => {
    setColumns(getColumns(tables));
    setTableRows(getRows(tables));
  }, [tables]);

  const updateRows = (rows: Row[], data: RowsChangeData<Row, unknown>) => {
    const columnKeys = tableColumns.map(column => column.key);
    const colIndex = columnKeys.indexOf(data.column.key);
    if (colIndex === 0) {
      // Update x-axis values of all data tables
      tables.map((dataTable, tableIndex) => {
        const table: string[][] = [];
        rows.map(row =>
          table.push([
            row[tableColumns[0].key],
            row[tableColumns[tableIndex + 1].key],
          ])
        );

        const newTable = {...dataTable};
        newTable.table = table;
        if (onTableChange) {
          onTableChange(newTable);
        }
      });
    } else {
      // Update y values of one table
      const tableIndex = colIndex - 1;
      const table: string[][] = [];
      rows.map(row =>
        table.push([row[tableColumns[0].key], row[data.column.key]])
      );

      const newTable = {...tables[tableIndex]};
      newTable.table = table;
      if (onTableChange) {
        onTableChange(newTable);
      }
    }
    setTableRows(rows);
  };

  return (
    <DataGrid
      columns={tableColumns}
      rows={tableRows}
      onRowsChange={updateRows}
      className="rdg-light"
    />
  );
};
