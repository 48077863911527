import './ResizeMarker.css';

export enum Orientation {
  TopLeft,
  TopRight,
  BottomLeft,
  BottomRight,
  TopBottom,
  LeftRight,
  None,
}

type ResizeMarkerPropType = {
  x: number;
  y: number;
  setX: (x: number) => void;
  setY: (y: number) => void;
  onUpdate?: (x: number, y: number, orientation: Orientation) => void;
  size?: number;
  color?: string;
  orientation?: Orientation;
};

export const ResizeMarker = ({
  x,
  y,
  setX,
  setY,
  onUpdate,
  color,
  size = 10,
  orientation = Orientation.None,
}: ResizeMarkerPropType) => {
  const cursorStyle = {
    [Orientation.TopLeft]: 'nw-resize',
    [Orientation.TopRight]: 'ne-resize',
    [Orientation.BottomLeft]: 'sw-resize',
    [Orientation.BottomRight]: 'se-resize',
    [Orientation.TopBottom]: 'ns-resize',
    [Orientation.LeftRight]: 'we-resize',
    [Orientation.None]: '',
  }[orientation];

  const onMouseDown = (event: React.MouseEvent<HTMLElement>) => {
    const startPosition = {x: event.pageX, y: event.pageY};
    const changeCursorElement = document.getElementsByTagName('body')[0];
    changeCursorElement.style.cursor = cursorStyle;

    function onMouseMove(event: MouseEvent) {
      if (event.pageX !== startPosition.x || event.pageY !== startPosition.y) {
        setX(x + event.pageX - startPosition.x);
        setY(y + event.pageY - startPosition.y);
      }
      event.preventDefault();
      event.stopPropagation();
      return false;
    }

    function onMouseUp(event: MouseEvent) {
      document.body.removeEventListener('mousemove', onMouseMove);
      const newX = x + event.pageX - startPosition.x;
      const newY = y + event.pageY - startPosition.y;
      setX(newX);
      setY(newY);
      changeCursorElement.style.cursor = '';
      onUpdate && onUpdate(newX, newY, orientation);
    }

    document.body.addEventListener('mousemove', onMouseMove);
    document.body.addEventListener('mouseup', onMouseUp, {once: true});
  };

  return (
    <div
      className="resize-marker"
      onMouseDown={onMouseDown}
      style={{
        left: x - size / 2,
        top: y - size / 2,
        width: size,
        height: size,
        cursor: cursorStyle,
        backgroundColor: color,
      }}
    ></div>
  );
};
