import {useState} from 'react';
import {useMutation, useQueryClient} from '@tanstack/react-query';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import {updateAxis} from '../../api/api-functions';
import {AxisType, TickType} from '../../api/api-types';

import {ResizeMarker, Orientation} from './ResizeMarker';
import {TickEditor} from './TickEditor';
import {LabelEditor, Anchor, Direction} from './LabelEditor';

import './AxisEditor.css';

type AxisEditorPropType = {
  axis: AxisType;
  scale: number;
};

export const AxisEditor = ({axis, scale}: AxisEditorPropType) => {
  const [axisX, setAxisX] = useState(axis.x);
  const [axisY, setAxisY] = useState(axis.y);
  const [axisWidth, setAxisWidth] = useState(axis.width);
  const [axisHeight, setAxisHeight] = useState(axis.height);
  const left = axisX * scale;
  const right = (axisX + axisWidth) * scale;
  const top = axisY * scale;
  const bottom = (axisY + axisHeight) * scale;
  const [axisTicks, setAxisTicks] = useState(axis.ticks);

  const queryClient = useQueryClient();

  const updateAxisMutation = useMutation(updateAxis, {
    onSuccess: data => {
      queryClient.invalidateQueries(['chart_objects', axis.image]);
    },
  });

  const handleUpdateAxisRect = (
    x: number,
    y: number,
    orientation: Orientation
  ) => {
    let newTop = top;
    let newLeft = left;
    let newRight = right;
    let newBottom = bottom;
    switch (orientation) {
      case Orientation.TopLeft:
        newLeft = x;
        newTop = y;
        break;
      case Orientation.TopRight:
        newRight = x;
        newTop = y;
        break;
      case Orientation.BottomLeft:
        newLeft = x;
        newBottom = y;
        break;
      case Orientation.BottomRight:
        newRight = x;
        newBottom = y;
        break;
    }
    const axisX = newLeft / scale;
    const axisY = newTop / scale;
    const axisWidth = newRight / scale - axisX;
    const axisHeight = newBottom / scale - axisY;
    axis.x = Math.round(axisX);
    axis.y = Math.round(axisY);
    axis.width = Math.round(axisWidth);
    axis.height = Math.round(axisHeight);
    updateAxisMutation.mutate(axis);
  };

  const handleUpdateAxisTicks = (ticks: TickType[]) => {
    axis.ticks = ticks;
    updateAxisMutation.mutate(axis);
  };

  const addTick = () => {
    if (axis.direction === 'horizontal') {
      axisTicks.push([axis.x, '0']);
    } else {
      axisTicks.push([axis.y, '0']);
    }
    setAxisTicks([...axisTicks]);
    handleUpdateAxisTicks(axisTicks);
  };

  return (
    <>
      <div
        className="axis-rect"
        key={axis.id}
        style={{
          left: left,
          top: top,
          width: right - left,
          height: bottom - top,
        }}
      ></div>

      <LabelEditor
        label={axis.label}
        onChange={label => {
          axis.label = label;
          updateAxisMutation.mutate(axis);
        }}
        x={axis.direction === 'horizontal' ? left + (right - left) / 2 : left}
        y={axis.direction === 'horizontal' ? bottom : top + (bottom - top) / 2}
        anchor={axis.direction === 'horizontal' ? Anchor.North : Anchor.East}
        direction={
          axis.direction === 'vertical'
            ? Direction.Vertical
            : Direction.Horizontal
        }
      />

      <Tooltip title="Add Tick">
        <IconButton
          className="add-tick-button"
          style={{
            left: right,
            top: top,
          }}
          color="primary"
          onClick={addTick}
        >
          <AddCircleOutlineIcon />
        </IconButton>
      </Tooltip>

      <ResizeMarker
        x={left}
        y={top}
        setX={x => {
          setAxisX(x / scale);
          setAxisWidth((right - x) / scale);
        }}
        setY={y => {
          setAxisY(y / scale);
          setAxisHeight((bottom - y) / scale);
        }}
        orientation={Orientation.TopLeft}
        onUpdate={handleUpdateAxisRect}
      />
      <ResizeMarker
        x={left}
        y={bottom}
        setX={x => {
          setAxisX(x / scale);
          setAxisWidth((right - x) / scale);
        }}
        setY={y => setAxisHeight(y / scale - axisY)}
        orientation={Orientation.BottomLeft}
        onUpdate={handleUpdateAxisRect}
      />
      <ResizeMarker
        x={right}
        y={top}
        setX={x => setAxisWidth(x / scale - axisX)}
        setY={y => {
          setAxisY(y / scale);
          setAxisHeight((bottom - y) / scale);
        }}
        orientation={Orientation.TopRight}
        onUpdate={handleUpdateAxisRect}
      />
      <ResizeMarker
        x={right}
        y={bottom}
        setX={x => setAxisWidth(x / scale - axisX)}
        setY={y => setAxisHeight(y / scale - axisY)}
        orientation={Orientation.BottomRight}
        onUpdate={handleUpdateAxisRect}
      />

      {axisTicks.map((item, i) => (
        <TickEditor
          key={`$axis-{axis.id}-tick-${i}`}
          label={axisTicks[i][1]}
          position={axisTicks[i][0] * scale}
          minPosition={axis.direction === 'horizontal' ? left : top}
          maxPosition={axis.direction === 'horizontal' ? right : bottom}
          tickStart={axis.direction === 'horizontal' ? top : left}
          tickEnd={axis.direction === 'horizontal' ? bottom : right}
          axisDirection={axis.direction}
          setPosition={(position: number) => {
            axisTicks[i][0] = position / scale;
            setAxisTicks([...axisTicks]);
          }}
          onUpdate={(position: number, label: string) => {
            axisTicks[i][0] = position / scale;
            axisTicks[i][1] = label;
            setAxisTicks([...axisTicks]);
            handleUpdateAxisTicks(axisTicks);
          }}
          onRemove={() => {
            axisTicks.splice(i, 1);
            setAxisTicks([...axisTicks]);
            handleUpdateAxisTicks(axisTicks);
          }}
        />
      ))}
    </>
  );
};

export default AxisEditor;
