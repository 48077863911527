import {useEffect, useState} from 'react';
import {useMutation, useQueryClient} from '@tanstack/react-query';

import {updateDataTable} from '../../api/api-functions';
import {AxisType, DataTableType} from '../../api/api-types';

import {ResizeMarker, Orientation} from './ResizeMarker';
import {Line} from './Line';

// Map chart value into canvas coordinate
function mapPoint(value: number | string, axis: AxisType, scale: number) {
  if (axis.axis_type === 'categorical') {
    for (let i = 0; i < axis.ticks.length; i++) {
      if (axis.ticks[i][1] === value) {
        return axis.ticks[i][0] * scale;
      }
    }
    return 0;
  } else if (axis.axis_type === 'linear') {
    return ((Number(value) - axis.intercept) / axis.gradient) * scale;
  } else {
    return 0;
  }
}

function getLinePoints(
  xAxis: AxisType,
  yAxis: AxisType,
  dataTable: DataTableType,
  scale: number
) {
  const points: Array<Point> = [];
  dataTable.table.map(row => {
    const x = mapPoint(row[0], xAxis, scale);
    const y = mapPoint(row[1], yAxis, scale);
    points.push({x, y});
  });
  return points;
}

type LineEditorPropType = {
  table: DataTableType;
  xAxis?: AxisType;
  yAxis?: AxisType;
  scale: number;
};

type Point = {
  x: number;
  y: number;
};

export const LineEditor = ({
  table,
  xAxis,
  yAxis,
  scale,
}: LineEditorPropType) => {
  const [points, setPoints] = useState(Array<Point>);
  const [pointPairs, setPointPairs] = useState(Array<Array<Point>>);

  const queryClient = useQueryClient();
  const updateTableMutation = useMutation(updateDataTable, {
    onSuccess: data => {
      queryClient.invalidateQueries(['data_table', data.image]);
    },
  });

  const handleUpdateTable = () => {
    for (let i = 0; i < table.table.length; i++) {
      const point_y = points[i].y;
      if (yAxis && yAxis.axis_type === 'linear') {
        const y_value = (point_y / scale) * yAxis.gradient + yAxis.intercept;
        table.table[i][1] = `${y_value}`;
      }

      updateTableMutation.mutate(table);
    }
  };

  useEffect(() => {
    if (xAxis && yAxis) {
      const newPoints = getLinePoints(xAxis, yAxis, table, scale);
      setPoints(newPoints);
      const pairs = [];
      for (let i = 0; i < newPoints.length - 1; i++) {
        pairs.push([newPoints[i], newPoints[i + 1]]);
      }
      setPointPairs(pairs);
    }
  }, [table, xAxis, yAxis, scale]);

  return (
    <>
      {points.map((point, i) => (
        <ResizeMarker
          key={`marker-${i}`}
          x={point.x}
          y={point.y}
          color={table.color}
          setX={x => {}}
          setY={y => {
            points[i].y = y;
            setPoints([...points]);
          }}
          orientation={Orientation.TopBottom}
          onUpdate={handleUpdateTable}
        />
      ))}
      {pointPairs.map((pair, i) => (
        <Line key={`line-${i}`} p1={pair[0]} p2={pair[1]} color={table.color} />
      ))}
    </>
  );
};

export default LineEditor;
