import {Line} from 'react-konva';

import {AxisType, DataTableType} from '../../api/api-types';

type TablePlotPropType = {
  xAxis: AxisType;
  yAxis: AxisType;
  dataTable: DataTableType;
  scale: number;
};

// Map chart value into canvas coordinate
function mapPoint(value: number | string, axis: AxisType, scale: number) {
  if (axis.axis_type === 'categorical') {
    for (let i = 0; i < axis.ticks.length; i++) {
      if (axis.ticks[i][1] === value) {
        return axis.ticks[i][0] * scale;
      }
    }
    return 0;
  } else if (axis.axis_type === 'linear') {
    return ((Number(value) - axis.intercept) / axis.gradient) * scale;
  } else {
    return 0;
  }
}

function getLinePoints(
  xAxis: AxisType,
  yAxis: AxisType,
  dataTable: DataTableType,
  scale: number
) {
  const points: Array<number> = [];
  dataTable.table.map(row => {
    points.push(mapPoint(row[0], xAxis, scale));
    points.push(mapPoint(row[1], yAxis, scale));
  });
  return points;
}

export const TablePlot = ({
  xAxis,
  yAxis,
  dataTable,
  scale,
}: TablePlotPropType) => {
  const points = getLinePoints(xAxis, yAxis, dataTable, scale);

  return (
    <>
      <Line points={points} stroke={dataTable.color} strokeWidth={2} />
    </>
  );
};

export default TablePlot;
