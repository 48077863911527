import {Typography} from '@mui/material';
import {Link} from 'react-router-dom';
import {useMutation, useQueryClient} from '@tanstack/react-query';

import {ImageType} from '../../api/api-types';
import {deleteImage} from '../../api/api-functions';

import {IconOverlay, IconTypes} from '../IconOverlay/IconOverlay';

import './ImageThumbnail.css';

type ImageThumbnailPropType = {
  image: ImageType;
};

export const ImageThumbnail = ({image}: ImageThumbnailPropType) => {
  const queryClient = useQueryClient();

  const mutation = useMutation(deleteImage, {
    onSuccess: () => {
      queryClient.invalidateQueries(['images']);
    },
  });

  const onDeleteImage = async (imageId: string) => {
    mutation.mutate(imageId);
  };

  return (
    <div className="image-thumbnail">
      <Link to={`/edit_chart/${image.id}`}>
        <Typography variant="h6" align="center">
          {image.name}
        </Typography>
      </Link>
      <IconOverlay
        onClick={() => onDeleteImage(image.id)}
        icon={IconTypes.Delete}
        color="primary"
      >
        <Link to={`/edit_chart/${image.id}`}>
          <div className="image-thumbnail-container">
            <img className="image-thumbnail-image" src={image.url} />
          </div>
        </Link>
      </IconOverlay>
    </div>
  );
};

export default ImageThumbnail;
