import {useQuery} from '@tanstack/react-query';
import Grid from '@mui/material/Grid';

import {ImageThumbnail} from '../ImageThumbnail/ImageThumbnail';
import {MaskThumbnail} from '../MaskThumbnail/MaskThumbnail';
import {getImagesList} from '../../api/api-functions';
import {ImageType} from '../../api/api-types';

import './ImageList.css';

type ImageListPropType = {
  name?: string;
  image_type?: string;
  parent_image?: string;
};

export const ImageList = ({
  name = 'Default',
  image_type = 'chart',
  parent_image = undefined,
}: ImageListPropType) => {
  const queryInfo = useQuery(['images'], () =>
    getImagesList(image_type, parent_image)
  );

  if (queryInfo.isLoading || queryInfo.isFetching) {
    return <span>Loading...</span>;
  }

  if (queryInfo.isError) {
    return <span>Error loading image list</span>;
  }

  return queryInfo.data ? (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
    >
      {queryInfo.data.map((image: ImageType) =>
        image_type === 'chart' ? (
          <Grid key={`grid_${image.id}`} item xs={12} sm={6} md={4} lg={3}>
            <ImageThumbnail key={image.id} image={image}></ImageThumbnail>
          </Grid>
        ) : (
          <Grid key={`grid_${image.id}`} item xs={12} sm={6} md={4} lg={3}>
            <MaskThumbnail key={image.id} image={image}></MaskThumbnail>
          </Grid>
        )
      )}
    </Grid>
  ) : (
    <></>
  );
};

export default ImageList;
