import {useContext, useEffect} from 'react';
import {Navigate} from 'react-router-dom';
import {useQuery} from '@tanstack/react-query';

import {AppContext} from '../../context/AppContext';
import {ActionType} from '../../context/ActionTypes';

import {logoutUser} from '../../api/api-functions';

export function LogoutPage() {
  const {appState, appDispatch} = useContext(AppContext);
  const sessionQueryInfo = useQuery(['session'], () => logoutUser());

  useEffect(() => {
    if (sessionQueryInfo.isSuccess) {
      appDispatch({
        actionType: ActionType.setUserName,
        value: null,
      });

      appDispatch({
        actionType: ActionType.setUserId,
        value: null,
      });

      if (sessionQueryInfo.data['X-CSRFToken']) {
        appDispatch({
          actionType: ActionType.setCSRFToken,
          value: sessionQueryInfo.data['X-CSRFToken'],
        });
        localStorage.setItem(
          'X-CSRFToken',
          sessionQueryInfo.data['X-CSRFToken']
        );
      }
      appDispatch({
        actionType: ActionType.setAuthenticated,
        value: false,
      });
    }
  }, [sessionQueryInfo.data]);

  console.log('Render logout page, auth:', appState.isAuthenticated);
  // if (appState.isAuthenticated) {
  //   logoutUser().then(result => {
  //     appDispatch({
  //       actionType: ActionType.setUserName,
  //       value: null,
  //     });
  //     appDispatch({
  //       actionType: ActionType.setUserId,
  //       value: null,
  //     });
  //     if (result['X-CSRFToken']) {
  //       appDispatch({
  //         actionType: ActionType.setCSRFToken,
  //         value: result['X-CSRFToken'],
  //       });
  //     }
  //     appDispatch({
  //       actionType: ActionType.setAuthenticated,
  //       value: false,
  //     });
  //     console.log('Then after dispatch, auth:', appState.isAuthenticated);
  //   });
  //   console.log('End render logout, auth:', appState.isAuthenticated);
  // }
  return <Navigate to="/login" />;
}

export default LogoutPage;
