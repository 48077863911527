import {useState, useEffect, useRef} from 'react';
import {useQuery, useQueryClient, useMutation} from '@tanstack/react-query';

import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

import {
  getChartObjects,
  recognizeChartObjects,
  getProcessingTask,
  getImageDataTables,
} from '../../api/api-functions';
import {ImageType, ProcessingTaskType} from '../../api/api-types';

import {DataTableList} from '../DataTableList/DataTableList';

import {ChartCanvas} from './ChartCanvas';
import {ChartEditor} from './ChartEditor';
import {IconOverlay, IconTypes} from '../IconOverlay/IconOverlay';

import {useNotification} from '../Notification/UseNotification';
import {ModalDialog} from '../ModalDialog/ModalDialog';
import {DataExtractionDialog} from '../DataExtractionDialog/DataExtractionDialog';

import './ChartProcessor.css';

type ChartProcessorPropType = {
  image: ImageType;
};

export const ChartProcessor = ({image}: ChartProcessorPropType) => {
  const chartObjectsQueryInfo = useQuery(['chart_objects', image.id], () =>
    getChartObjects(image.id)
  );

  const dataTablesQueryInfo = useQuery(['data_table', image.id], () =>
    getImageDataTables(image.id)
  );

  // Chart editor maximized state
  const [chartMaximized, setChartMaximized] = useState(false);

  // Current data table selected in tabs with data table editors
  const [currentTable, setCurrentTable] = useState(0);
  const imageRef = useRef<HTMLImageElement>(null);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [activeTask, setActiveTask] = useState<ProcessingTaskType | null>(null);
  const [showResetAxisDialog, setShowResetAxisDialog] = useState(false);

  const {showNotification, notificationElement} = useNotification();

  const queryClient = useQueryClient();
  const recognizeObjectsMutation = useMutation(recognizeChartObjects, {
    onSuccess: data => {
      queryClient.invalidateQueries(['chart_objects', image.id]);
      queryClient.invalidateQueries(['data_table', image.id]);
      queryClient.invalidateQueries(['images']);
      showNotification('Chart recognition job started. Please wait.', 'info');
      setActiveTask(data);
    },
  });

  const handleRecognize = () => {
    if (
      !activeTask ||
      (activeTask.stage !== 'text_recognition' &&
        activeTask.stage !== 'objects_detection')
    ) {
      if (chartObjectsQueryInfo.isSuccess) {
        // Axes already recognized show confirmation dialog
        setShowResetAxisDialog(true);
      } else {
        recognizeObjectsMutation.mutate(image.id);
      }
    }
  };

  const switchChartMaximized = () => {
    setChartMaximized(!chartMaximized);
    // Force window resize event to handle canvas resize
    setTimeout(() => window.dispatchEvent(new Event('resize')), 10);
  };

  // Check status of active processing task if it's present
  useEffect(() => {
    const timer_id = setInterval(() => {
      if (activeTask) {
        // Check status of active task
        const fetchData = async () => {
          const taskStatus = await getProcessingTask(activeTask.id);
          if (taskStatus.state === 'finished') {
            setActiveTask(null);
            queryClient.invalidateQueries(['chart_objects', image.id]);
            queryClient.invalidateQueries(['images']);
            showNotification('Chart recognition finished.', 'info');
          } else if (taskStatus.state === 'fail') {
            setActiveTask(null);
            queryClient.invalidateQueries(['chart_objects', image.id]);
            queryClient.invalidateQueries(['images']);
            showNotification('Chart recognition failed!', 'error');
          } else {
            setActiveTask(taskStatus);
          }
        };
        fetchData();
      }
    }, 5000);
    return () => {
      clearInterval(timer_id);
    };
  }, [activeTask]);

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="flex-start"
      spacing={2}
    >
      {notificationElement}
      <img
        src={image.url}
        ref={imageRef}
        className="hidden-image"
        onLoad={() => setImageLoaded(true)}
      />
      <Grid item xs={6} sm={6} md={4} lg={3}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="flex-start"
          spacing={2}
        >
          <Grid item xs={12} sm={12} md={12} lg={12}>
            {imageLoaded &&
              imageRef.current &&
              chartObjectsQueryInfo.isSuccess && (
                <>
                  <IconOverlay
                    onClick={switchChartMaximized}
                    icon={chartMaximized ? IconTypes.ZoomIn : IconTypes.ZoomOut}
                  >
                    <ChartCanvas
                      image={imageRef.current}
                      axes={Object.values(chartObjectsQueryInfo.data['axes'])}
                      dataTables={dataTablesQueryInfo.data}
                    />
                  </IconOverlay>
                  <ModalDialog
                    show={chartMaximized}
                    title="Edit chart"
                    onClose={() => setChartMaximized(false)}
                    fullScreen={true}
                  >
                    <ChartEditor
                      image={image}
                      axes={Object.values(chartObjectsQueryInfo.data['axes'])}
                      dataTables={dataTablesQueryInfo.data}
                    />
                  </ModalDialog>
                </>
              )}
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Button
              color="primary"
              variant="contained"
              sx={{width: '100%', marginBottom: 2, maxWidth: '30em'}}
              onClick={handleRecognize}
            >
              {activeTask &&
              (activeTask.stage === 'text_recognition' ||
                activeTask.stage === 'objects_detection') ? (
                <>
                  <CircularProgress
                    color="secondary"
                    sx={{marginRight: 2}}
                    size={'1.5em'}
                  />
                  Processing...
                </>
              ) : (
                'Recognize Axes'
              )}
            </Button>
            <ModalDialog
              show={showResetAxisDialog}
              title="Reset current axes and data tables?"
              onClose={() => {
                setShowResetAxisDialog(false);
              }}
              fullScreen={false}
              actions={[
                {
                  label: 'Proceed',
                  action: () => {
                    setShowResetAxisDialog(false);
                    recognizeObjectsMutation.mutate(image.id);
                  },
                },
              ]}
            >
              <Typography component="div">
                This action will remove current axes settings and data tables.
                Proceed?
              </Typography>
            </ModalDialog>

            {chartObjectsQueryInfo.isSuccess && (
              <DataExtractionDialog image={image} />
            )}
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={6} sm={6} md={8} lg={9}>
        {dataTablesQueryInfo.isSuccess && (
          <DataTableList image={image} onTableChange={setCurrentTable} />
        )}
      </Grid>
    </Grid>
  );
};

export default ChartProcessor;
