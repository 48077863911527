import * as React from 'react';
import Grid from '@mui/material/Grid';
import PaletteIcon from '@mui/icons-material/Palette';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import './ColorPicker.css';

const defaultPalette = [
  '#aa2e25',
  '#f44336',
  '#f6685e',
  '#a31545',
  '#e91e63',
  '#ed4b82',
  '#6d1b7b',
  '#9c27b0',
  '#af52bf',
  '#482880',
  '#673ab7',
  '#8561c5',
  '#2c387e',
  '#3f51b5',
  '#6573c3',
  '#1769aa',
  '#2196f3',
  '#4dabf5',
  '#0276aa',
  '#03a9f4',
  '#35baf6',
  '#008394',
  '#00bcd4',
  '#33c9dc',
  '#00695f',
  '#009688',
  '#33ab9f',
  '#357a38',
  '#4caf50',
  '#6fbf73',
  '#618833',
  '#8bc34a',
  '#a2cf6e',
  '#8f9a27',
  '#cddc39',
  '#d7e360',
  '#b2a429',
  '#ffeb3b',
  '#ffef62',
  '#b28704',
  '#ffc107',
  '#ffcd38',
  '#b26a00',
  '#ff9800',
  '#ffac33',
  '#b23c17',
  '#ff5722',
  '#ff784e',
];

type ColorPickerPropType = {
  color: string;
  onColorChange?: (color: string) => void;
  palette?: string[];
};

export const ColorPicker = ({
  color,
  onColorChange,
  palette = defaultPalette,
}: ColorPickerPropType) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <React.Fragment>
      <Tooltip title="Select color">
        <IconButton
          sx={{
            backgroundColor: color,
            '&:hover': {
              backgroundColor: color,
            },
          }}
          onClick={handleClick}
          size="small"
          aria-controls={open ? 'color-pick-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
        >
          <PaletteIcon sx={{color: 'rgba(255,255,255,0.5)'}} />
        </IconButton>
      </Tooltip>

      <Menu
        anchorEl={anchorEl}
        id="color-pick-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
      >
        <Grid
          container
          spacing={0}
          sx={{maxWidth: '12.2em', padding: '0.11em'}}
        >
          {palette.map(pColor => (
            <Grid key={pColor} item xs={2}>
              <MenuItem
                className="colorPickItem"
                sx={{
                  backgroundColor: pColor,
                  '&:hover': {
                    backgroundColor: pColor,
                  },
                }}
                onClick={() => onColorChange && onColorChange(pColor)}
              >
                {pColor === color && <CheckCircleOutlineIcon />}
              </MenuItem>
            </Grid>
          ))}
        </Grid>
      </Menu>
    </React.Fragment>
  );
};

export default ColorPicker;
