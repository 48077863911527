import {createContext} from 'react';

import {Action, ActionType} from './ActionTypes';

interface AppContextStateInterface {
  CSRFToken: string | null;
  userName: string | null;
  userId: string | null;
  isAdmin: boolean;
  isAuthenticated: boolean | null;
}

interface AppContextInterface {
  appState: AppContextStateInterface;
  appDispatch: React.Dispatch<Action>;
}

export const initialState: AppContextStateInterface = {
  CSRFToken: null,
  userName: null,
  userId: null,
  isAdmin: false,
  isAuthenticated: null, // Default state - unknown
};

export const appReducer = (state = initialState, action: Action) => {
  const newState = {...state};
  switch (action.actionType) {
    case ActionType.setCSRFToken:
      newState.CSRFToken = action.value;
      return newState;
    case ActionType.setUserName:
      newState.userName = action.value;
      return newState;
    case ActionType.setUserId:
      newState.userId = action.value;
      return newState;
    case ActionType.setAuthenticated:
      newState.isAuthenticated = action.value;
      return newState;
    default:
      return newState;
  }
};

export const AppContext = createContext<AppContextInterface>({
  appState: initialState,
  appDispatch: action =>
    console.error(
      'Dispatched action outside of an AuthContext provider',
      action
    ),
});
