export enum ActionType {
  setCSRFToken = 'SET_CSRF_TOKEN',
  setUserName = 'SET_USERNAME',
  setUserId = 'SET_USER_ID',
  setAuthenticated = 'SET_AUTHENTICATED',
}

// export interface Action {
//   actionType: ActionType;
//   value: string | null;
// }

export type Action =
  | {actionType: ActionType.setCSRFToken; value: string | null}
  | {actionType: ActionType.setUserName; value: string | null}
  | {actionType: ActionType.setUserId; value: string | null}
  | {actionType: ActionType.setAuthenticated; value: boolean | null};
