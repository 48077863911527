import {useState, useEffect, useRef} from 'react';
import {useQuery, useQueryClient, useMutation} from '@tanstack/react-query';

import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';

import {ImageType, AxisType, ProcessingTaskType} from '../../api/api-types';
import {
  getChartObjects,
  extractChartData,
  getProcessingTask,
  getImagesList,
} from '../../api/api-functions';
import {useNotification} from '../Notification/UseNotification';

import './DataExtractionDialog.css';

type DataExtractionDialogPropType = {
  image: ImageType;
};

export const DataExtractionDialog = ({image}: DataExtractionDialogPropType) => {
  const [open, setOpen] = useState(false);
  const [xAxis, setXAxis] = useState('');
  const [yAxis, setYAxis] = useState('');
  const [seriesName, setSeriesName] = useState('');
  const [targetMask, setTargetMask] = useState('');
  const [activeTask, setActiveTask] = useState<ProcessingTaskType | null>(null);
  const formRef = useRef<HTMLFormElement>(null);

  const masksQueryInfo = useQuery(['images'], () =>
    getImagesList('mask', image.id)
  );

  const chartObjectsQueryInfo = useQuery(['chart_objects', image.id], () =>
    getChartObjects(image.id)
  );

  useEffect(() => {
    updateAxes();
  }, [chartObjectsQueryInfo.data]);

  function updateAxes() {
    if (chartObjectsQueryInfo.isSuccess) {
      // Set default axis values
      if (
        Object.values(chartObjectsQueryInfo.data['axes']).filter(
          (axis: AxisType) => axis.direction === 'horizontal'
        ).length > 0
      ) {
        setXAxis(
          Object.values(chartObjectsQueryInfo.data['axes'])
            .filter((axis: AxisType) => axis.direction === 'horizontal')[0]
            .id.toString()
        );
      } else {
        setXAxis('');
      }
      if (
        Object.values(chartObjectsQueryInfo.data['axes']).filter(
          (axis: AxisType) => axis.direction === 'vertical'
        ).length > 0
      ) {
        setYAxis(
          Object.values(chartObjectsQueryInfo.data['axes'])
            .filter((axis: AxisType) => axis.direction === 'vertical')[0]
            .id.toString()
        );
      } else {
        setYAxis('');
      }
    }
  }

  const queryClient = useQueryClient();
  const {showNotification, notificationElement} = useNotification();

  const extractDataMutation = useMutation(
    () => extractChartData(targetMask, xAxis, yAxis, seriesName),
    {
      onSuccess: data => {
        queryClient.invalidateQueries(['data_table', image.id]);
        showNotification('Data extraction started', 'info');
        setActiveTask(data);
      },
    }
  );

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleExtract = () => {
    if (xAxis && yAxis && targetMask) {
      extractDataMutation.mutate();
      setOpen(false);
    } else if (formRef && formRef.current) {
      formRef.current.reportValidity();
    }
  };

  // Check status of active processing task if it's present
  useEffect(() => {
    const timer_id = setInterval(() => {
      if (activeTask) {
        // Check status of active task
        const fetchData = async () => {
          const taskStatus = await getProcessingTask(activeTask.id);
          if (taskStatus.state === 'finished') {
            setActiveTask(null);
            queryClient.invalidateQueries(['data_table', image.id]);
            showNotification('Chart data extracted.', 'info');
          } else if (taskStatus.state === 'fail') {
            setActiveTask(null);
            queryClient.invalidateQueries(['data_table', image.id]);
            showNotification('Data extraction failed!', 'error');
          } else {
            setActiveTask(taskStatus);
          }
        };
        fetchData();
      }
    }, 3000);
    return () => {
      clearInterval(timer_id);
    };
  }, [activeTask]);

  return chartObjectsQueryInfo.isSuccess &&
    Object.values(chartObjectsQueryInfo.data['axes']).filter(
      (axis: AxisType) => axis.direction === 'horizontal'
    ).length > 0 &&
    Object.values(chartObjectsQueryInfo.data['axes']).filter(
      (axis: AxisType) => axis.direction === 'vertical'
    ).length > 0 ? (
    <>
      {notificationElement}
      <div className="data-extraction-dialog">
        <Button
          variant="contained"
          color="primary"
          sx={{width: '100%', maxWidth: '30em'}}
          onClick={handleClickOpen}
        >
          Extract Data
        </Button>
        <Dialog open={open} onClose={handleClose}>
          <form ref={formRef}>
            <DialogTitle>Data extraction</DialogTitle>
            <DialogContent>
              <DialogContentText component={'span'} sx={{color: '#000'}}>
                <FormControl>
                  <FormLabel
                    id="masks-group-label"
                    sx={{marginBottom: 1}}
                    color="primary"
                  >
                    Select chart mask
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="masks-group-label"
                    name="masks-buttons-group"
                    onChange={e => {
                      setTargetMask(e.target.value);
                    }}
                  >
                    <Grid
                      container
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      spacing={2}
                    >
                      {masksQueryInfo.isSuccess ? (
                        masksQueryInfo.data.map(mask => (
                          <Grid key={mask.id} item xs={12} sm={6} md={6} lg={6}>
                            <FormControlLabel
                              key={`mask_${mask.id}`}
                              value={mask.id}
                              control={<Radio required={true} />}
                              label={
                                <img
                                  className="data-extraction-mask"
                                  src={mask.image}
                                  alt="Chart mask"
                                />
                              }
                            />
                          </Grid>
                        ))
                      ) : (
                        <div>Loading masks...</div>
                      )}
                    </Grid>
                  </RadioGroup>

                  {chartObjectsQueryInfo.isSuccess &&
                  Object.values(chartObjectsQueryInfo.data['axes']).filter(
                    (axis: AxisType) => axis.direction === 'horizontal'
                  ).length > 0 &&
                  Object.values(chartObjectsQueryInfo.data['axes']).filter(
                    (axis: AxisType) => axis.direction === 'vertical'
                  ).length > 0 ? (
                    <Grid
                      container
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      spacing={2}
                      sx={{marginTop: 2}}
                    >
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <FormControl fullWidth>
                          <InputLabel id="xaxis-select-label" color="primary">
                            Horizontal axis
                          </InputLabel>
                          <Select
                            name="horizontal_axis"
                            labelId="xaxis-select-label"
                            label="Horizontal axis"
                            value={String(
                              Object.values(
                                chartObjectsQueryInfo.data['axes']
                              ).filter(
                                (axis: AxisType) =>
                                  axis.direction === 'horizontal'
                              )[0].id
                            )}
                            onChange={e =>
                              typeof e.target.value === 'string' &&
                              setXAxis(e.target.value)
                            }
                          >
                            {Object.values(chartObjectsQueryInfo.data['axes'])
                              .filter(
                                (axis: AxisType) =>
                                  axis.direction === 'horizontal'
                              )
                              .map((axis: AxisType) => (
                                <MenuItem
                                  key={`haxis_${axis.id}`}
                                  value={String(axis.id)}
                                >
                                  {axis.label}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <FormControl fullWidth>
                          <InputLabel id="yaxis-select-label" color="primary">
                            Vertical axis
                          </InputLabel>
                          <Select
                            name="vertical_axis"
                            labelId="yaxis-select-label"
                            label="Vertical axis"
                            value={String(
                              Object.values(
                                chartObjectsQueryInfo.data['axes']
                              ).filter(
                                (axis: AxisType) =>
                                  axis.direction === 'vertical'
                              )[0].id
                            )}
                            onChange={e =>
                              typeof e.target.value === 'string' &&
                              setYAxis(e.target.value)
                            }
                          >
                            {Object.values(chartObjectsQueryInfo.data['axes'])
                              .filter(
                                (axis: AxisType) =>
                                  axis.direction === 'vertical'
                              )
                              .map((axis: AxisType) => (
                                <MenuItem
                                  key={`vaxis_${axis.id}`}
                                  value={String(axis.id)}
                                >
                                  {axis.label}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      </Grid>

                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <FormControl fullWidth>
                          <TextField
                            id="series-name"
                            name="series_name"
                            label="Series Name"
                            variant="outlined"
                            onChange={e => {
                              setSeriesName(e.target.value);
                            }}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                  ) : (
                    <div>Loading axes...</div>
                  )}
                </FormControl>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleExtract}
                color="primary"
                variant="contained"
                autoFocus
              >
                Extract
              </Button>
              <Button onClick={handleClose} color="primary" variant="outlined">
                Close
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      </div>
    </>
  ) : (
    <></>
  );
};

export default DataExtractionDialog;
