import {useState, useEffect, useRef} from 'react';

import {AxisType, ImageType, DataTableType} from '../../api/api-types';

import {AxisEditor} from './AxisEditor';
import {LineEditor} from './LineEditor';
import './ChartEditor.css';

type ChartEditorPropType = {
  image: ImageType;
  axes: Array<AxisType>;
  dataTables?: Array<DataTableType>;
};

export const ChartEditor = ({
  image,
  axes,
  dataTables = [],
}: ChartEditorPropType) => {
  const [imageScale, setImageScale] = useState(1);

  const handleSizeChange = () => {
    const cWidth = window.innerWidth * 0.6;
    const cHeight = window.innerHeight * 0.5;
    const scaleX = cWidth / image.width;
    const scaleY = cHeight / image.height;
    const scale = Math.min(scaleX, scaleY, 2);
    setImageScale(scale);
  };

  useEffect(() => {
    handleSizeChange();
    window.addEventListener('resize', handleSizeChange);
  }, []);

  return (
    <div className="chart-editor-container">
      <img
        src={image.url}
        className="chart-editor-image"
        style={{
          width: image.width * imageScale,
          height: image.height * imageScale,
        }}
      />
      {axes.map((axis: AxisType) => (
        <AxisEditor key={`axis_${axis.id}`} axis={axis} scale={imageScale} />
      ))}
      {dataTables.map(table => (
        <LineEditor
          key={`series-${table.id}`}
          table={table}
          scale={imageScale}
          xAxis={axes.find(a => a.id === table.x_axis)}
          yAxis={axes.find(a => a.id === table.y_axis)}
        />
      ))}
    </div>
  );
};

export default ChartEditor;
