import CloseIcon from '@mui/icons-material/Close';
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';
import ZoomInMapIcon from '@mui/icons-material/ZoomInMap';
import DeleteIcon from '@mui/icons-material/Delete';

import './IconOverlay.css';

export enum IconTypes {
  ZoomIn,
  ZoomOut,
  Close,
  Delete,
}

type IconOverlayPropType = {
  children?: React.ReactNode;
  onClick?: () => void;
  icon?: IconTypes;
  color?:
    | 'disabled'
    | 'action'
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'error'
    | 'info'
    | 'success'
    | 'warning'
    | undefined;
};

export const IconOverlay = (props: IconOverlayPropType) => {
  const {children, onClick, icon = IconTypes.Close, ...other} = props;

  return (
    <div className="icon-overlay">
      {(() => {
        switch (icon) {
          case IconTypes.ZoomIn:
            return (
              <ZoomInMapIcon
                color={props.color}
                className="icon"
                onClick={onClick}
              ></ZoomInMapIcon>
            );
          case IconTypes.ZoomOut:
            return (
              <ZoomOutMapIcon
                color={props.color}
                className="icon"
                onClick={onClick}
              ></ZoomOutMapIcon>
            );
          case IconTypes.Close:
            return (
              <CloseIcon
                color={props.color}
                className="icon"
                onClick={onClick}
              ></CloseIcon>
            );
          case IconTypes.Delete:
            return (
              <DeleteIcon
                color={props.color}
                className="icon"
                onClick={onClick}
              ></DeleteIcon>
            );
        }
      })()}
      {children}
    </div>
  );
};

export default IconOverlay;
