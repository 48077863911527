import * as React from 'react';
import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';
import Typography from '@mui/material/Typography';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

import {getImageDataTables, updateDataTable} from '../../api/api-functions';
import {DataTableType, ImageType} from '../../api/api-types';
import {DataTableEditor} from '../DataTableEditor/DataTableEditor';
import {DataTableSettingsDialog} from './DataTableSettingsDialog';
import {DataExportMenu} from './DataExportMenu';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

interface TableGroupType {
  [key: string]: {
    xColumn: string;
    yColumns: Array<string>;
    tables: Array<DataTableType>;
  };
}

function TabPanel(props: TabPanelProps) {
  const {children, value, index, ...other} = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{p: 3}}>{children}</Box>}
    </div>
  );
}

type DataTableListPropType = {
  image: ImageType;
  onTableChange?: React.Dispatch<React.SetStateAction<number>>;
};

export const DataTableList = ({
  image,
  onTableChange = () => {},
}: DataTableListPropType) => {
  const [currentTab, setCurrentTab] = React.useState(0);

  const dataTablesQueryInfo = useQuery(['data_table', image.id], () =>
    getImageDataTables(image.id)
  );

  // Group tables with the same x axis to display them in one grid
  const tableGroups: TableGroupType = {};
  if (dataTablesQueryInfo.isSuccess) {
    dataTablesQueryInfo.data
      .sort((a, b) => a.id - b.id)
      .map(dataTable => {
        if (!(String(dataTable.x_axis) in tableGroups)) {
          tableGroups[String(dataTable.x_axis)] = {
            xColumn: dataTable.columns[0],
            yColumns: [dataTable.columns[1]],
            tables: [],
          };
        }
        tableGroups[String(dataTable.x_axis)].tables.push(dataTable);
        if (
          tableGroups[String(dataTable.x_axis)].yColumns.indexOf(
            dataTable.columns[1]
          ) === -1
        ) {
          tableGroups[String(dataTable.x_axis)].yColumns.push(
            dataTable.columns[1]
          );
        }
      });
  }

  const queryClient = useQueryClient();
  const updateTableMutation = useMutation(updateDataTable, {
    onSuccess: data => {
      queryClient.invalidateQueries(['data_table', data.image]);
    },
  });

  const updateTable = (table: DataTableType) => {
    updateTableMutation.mutate(table);
  };

  const handleTabChange = (event: React.SyntheticEvent, tabIndex: number) => {
    setCurrentTab(tabIndex);
    onTableChange(tabIndex);
  };

  return (
    <Box>
      <Tabs value={currentTab} onChange={handleTabChange}>
        {Object.keys(tableGroups).map((xAxis, index) => (
          <Tab
            label={`${tableGroups[xAxis].xColumn} - ${tableGroups[xAxis].yColumns}`}
            key={`tab_label_${xAxis}`}
            value={index}
          ></Tab>
        ))}
      </Tabs>

      {Object.keys(tableGroups).map((xAxis, index) => (
        <TabPanel value={currentTab} index={index} key={`tab_${xAxis}`}>
          <Typography variant="body1" component="span" mr={3}>
            Click on table cell to edit
          </Typography>
          <DataTableSettingsDialog tables={tableGroups[xAxis].tables} />
          <DataExportMenu tables={tableGroups[xAxis].tables} />

          <DataTableEditor
            tables={tableGroups[xAxis].tables}
            onTableChange={updateTable}
          />
        </TabPanel>
      ))}
    </Box>
  );
};

export default DataTableList;
