import {useCallback, useMemo} from 'react';
import {useMutation, useQueryClient} from '@tanstack/react-query';
import {useDropzone} from 'react-dropzone';
import {Typography} from '@mui/material';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';

import {uploadImage} from '../../api/api-functions';

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column' as const, // Workaround for bug in csstype library for flexDirection
  alignItems: 'center',
  adjustContent: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#1C4772',
  borderStyle: 'dashed',
  backgroundColor: '#F2F5FA',
  color: '#666',
  outline: 'none',
  transition: 'border .24s ease-in-out',
  cursor: 'pointer',
};

const focusedStyle = {
  borderColor: '#2196f3',
};

const acceptStyle = {
  borderColor: '#00e676',
};

const rejectStyle = {
  borderColor: '#ff1744',
};

export const ImageUploadForm = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(uploadImage, {
    onSuccess: () => {
      queryClient.invalidateQueries(['images']);
    },
  });

  const handleDrop = useCallback((acceptedFiles: Array<File>) => {
    console.log('Droppped files:', acceptedFiles);
    acceptedFiles.map(file => mutation.mutate(file));
  }, []);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isFocused,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: {'image/*': ['.png', '.jpg', '.jpeg', '.bmp']},
    onDrop: handleDrop,
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  return (
    <>
      <div {...getRootProps({style})}>
        <input {...getInputProps()} />
        <div>
          <AddPhotoAlternateIcon sx={{color: '#1C4772'}} />
        </div>
        <div>
          {isDragActive ? (
            <Typography variant="body1">Drop the image here ...</Typography>
          ) : (
            <Typography variant="body1">
              Drop image files or click to select files
            </Typography>
          )}
        </div>
      </div>
    </>
  );
};

export default ImageUploadForm;
