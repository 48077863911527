import {apiInstance, authInstance} from './api-instance';
import {
  ImageType,
  ProcessingTaskType,
  ChartObjectsType,
  DataTableType,
  SessionType,
  AxisType,
  UrlType,
} from './api-types';

// Image model API

export const getImagesList = async (
  image_type?: string,
  parent_image?: string
) => {
  const response = await apiInstance.get<ImageType[]>('/images/', {
    params: {image_type: image_type, parent_image: parent_image},
  });
  return response.data;
};

export const getImage = async (image_id: string) => {
  const response = await apiInstance.get<ImageType>(`/images/${image_id}/`);

  if (response.status !== 200) throw new Error(response.statusText);

  return response.data;
};

export const uploadImage = async (file: File) => {
  const formData = new FormData();
  formData.append('file', file);
  const config = {
    headers: {
      'content-type': 'multipart/form-data',
    },
  };
  const response = await apiInstance.post<ImageType>(
    '/images/',
    {image: file, name: file.name},
    config
  );
  if (response.status !== 201) throw new Error(response.statusText);
  return response.data;
};

export const deleteImage = async (image_id: string) => {
  const response = await apiInstance.delete<ImageType>(`/images/${image_id}/`);
  if (response.status !== 204) throw new Error(response.statusText);
  return response;
};

export const updateImage = async (image: ImageType) => {
  const response = await apiInstance.patch<ImageType>(`/images/${image.id}/`, {
    id: image.id,
    // image: image.image,
    name: image.name,
    // creatd: image.creatd,
    // user: image.user,
    // width: image.width,
    // height: image.height,
    url: image.url,
  });
  if (response.status !== 200) throw new Error(response.statusText);
  return response.data;
};

// ProcessingTask model API

export const getProcessingTasksList = async () => {
  const response = await apiInstance.get<ProcessingTaskType[]>(
    '/processing_tasks/'
  );

  if (response.status !== 200) throw new Error(response.statusText);

  return response.data;
};

export const getProcessingTask = async (task_id: number) => {
  const response = await apiInstance.get<ProcessingTaskType>(
    `/processing_tasks/${task_id}/`
  );

  if (response.status !== 200) throw new Error(response.statusText);

  return response.data;
};

// DataTable model API
export const getMaskDataTables = async (mask_id: string) => {
  const response = await apiInstance.get<DataTableType[]>(
    `/data_tables/get_mask_data_tables/${mask_id}/`
  );
  if (response.status !== 200) throw new Error(response.statusText);
  return response.data;
};

export const getImageDataTables = async (image_id: string) => {
  const response = await apiInstance.get<DataTableType[]>(
    `/data_tables/get_image_data_tables/${image_id}/`
  );
  if (response.status !== 200) throw new Error(response.statusText);
  return response.data;
};

export const updateDataTable = async (data_table: DataTableType) => {
  const response = await apiInstance.patch<DataTableType>(
    `/data_tables/${data_table.id}/`,
    {
      id: data_table.id,
      table: data_table.table,
      color: data_table.color,
      name: data_table.name,
      precision: data_table.precision,
    }
  );
  if (response.status !== 200) throw new Error(response.statusText);
  return response.data;
};

export const exportDataTables = async (
  tableIds: Array<number>,
  format: string
) => {
  const response = await apiInstance.post<UrlType>(`/export_data_tables/`, {
    table_ids: tableIds,
    format: format,
  });
  if (response.status !== 200) throw new Error(response.statusText);
  return response.data;
};

export const deleteDataTable = async (data_table_id: number) => {
  const response = await apiInstance.delete(`/data_tables/${data_table_id}/`);
  if (response.status !== 204) throw new Error(response.statusText);
  return response;
};

// Chart processing functions

export const recognizeChartObjects = async (image_id: string) => {
  const response = await apiInstance.post<ProcessingTaskType>(
    `/recognize_chart_objects/${image_id}/`
  );
  if (response.status !== 200) throw new Error(response.statusText);
  return response.data;
};

export const getChartObjects = async (image_id: string) => {
  const response = await apiInstance.get<ChartObjectsType>(
    `/get_chart_objects/${image_id}/`
  );
  if (response.status !== 200) throw new Error(response.statusText);
  return response.data;
};

export const updateAxis = async (axis: AxisType) => {
  const response = await apiInstance.patch<AxisType>(`/axes/${axis.id}/`, {
    id: axis.id,
    image: axis.image,
    axis_type: axis.axis_type,
    label: axis.label,
    ticks: axis.ticks,
    gradient: axis.gradient,
    intercept: axis.intercept,
    direction: axis.direction,
    x: axis.x,
    y: axis.y,
    width: axis.width,
    height: axis.height,
  });
  if (response.status !== 200) throw new Error(response.statusText);
  return response.data;
};

export const getAxis = async (axis_id: number) => {
  const response = await apiInstance.get<AxisType>(`/axes/${axis_id}/`);
  if (response.status !== 200) throw new Error(response.statusText);
  return response.data;
};

export const extractChartData = async (
  maskId: string,
  xAxisId: string,
  yAxisId: string,
  seriesName: string
) => {
  const response = await apiInstance.post<ProcessingTaskType>(
    `/extract_chart_data/${maskId}/${xAxisId}/${yAxisId}/`,
    {series_name: seriesName}
  );
  if (response.status !== 200) throw new Error(response.statusText);
  return response.data;
};

// Auth API
export const loginUser = async (username: string, password: string) => {
  const response = await authInstance.post<SessionType>('/login/', {
    username: username,
    password: password,
  });
  if (response.status !== 200) throw new Error(response.statusText);
  return response.data;
};

export const logoutUser = async () => {
  const response = await authInstance.post<SessionType>('/logout/');
  if (response.status !== 200) throw new Error(response.statusText);
  return response.data;
};

export const getCSRF = async () => {
  const response = await authInstance.get('/csrf/', {withCredentials: true});
  if (response.status !== 200) throw new Error(response.statusText);
  return response.data;
};

export const getUserSession = async () => {
  const response = await authInstance.get<SessionType>('/session/', {
    withCredentials: true,
  });
  if (response.status !== 200) throw new Error(response.statusText);
  return response.data;
};
