import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import {ImageList} from '../../components/ImageList/ImageList';
import {ImageUploadForm} from '../../components/ImageUploadForm/ImageUploadForm';
import {Header} from '../../components/Header/Header';

export function ImagesOverview() {
  return (
    <main>
      <Header />

      <Grid
        container
        component="main"
        direction="row"
        justifyContent="center"
        alignItems="flex-start"
        spacing={2}
        sx={{padding: 2}}
      >
        <Grid item xs={6} sm={6} md={8} lg={9}>
          <Typography variant="h4" component="h4">
            All projects
          </Typography>
        </Grid>
        <Grid item xs={6} sm={6} md={4} lg={3}>
          <ImageUploadForm />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <ImageList />
        </Grid>
      </Grid>
    </main>
  );
}
