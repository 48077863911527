import {useQuery, useQueryClient, useMutation} from '@tanstack/react-query';
import {useParams as useRouterParams} from 'react-router-dom';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';

import {ChartProcessor} from '../../components/ChartProcessor/ChartProcessor';
import {Header} from '../../components/Header/Header';
import {getImage, updateImage} from '../../api/api-functions';

import './ChartPage.css';

export function ChartPage() {
  const router_params = useRouterParams();
  const queryInfo = useQuery(['images', router_params.image_id || ''], () =>
    getImage(router_params.image_id || '')
  );

  const updateImageMutation = useMutation(updateImage, {
    onSuccess: data => {},
  });

  const onRenameImage = async (imageName: string) => {
    if (queryInfo.isSuccess) {
      const image = queryInfo.data;
      image.name = imageName;
      updateImageMutation.mutate(image);
    }
  };

  return (
    <main className="app-container">
      <Header />
      <div className="page">
        {queryInfo.data ? (
          <Grid
            container
            component="div"
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
            spacing={2}
            sx={{padding: 2}}
          >
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <TextField
                inputProps={{style: {fontSize: 30}}}
                variant="standard"
                defaultValue={queryInfo.data.name}
                onChange={event => onRenameImage(event.target.value)}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              className="page-content-container"
            >
              <ChartProcessor image={queryInfo.data} />
            </Grid>
          </Grid>
        ) : (
          <div>Loading data...</div>
        )}
      </div>
    </main>
  );
}
